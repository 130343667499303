<template>
  <div>
    <b-overlay
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
      <b-row class="match-height">
          <transition
          mode="out-in"
          >
          <b-col md="8">
            <b-card>
                <b-form @submit.prevent="update">
                  <b-row>
                    <!-- title -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Name')"
                        label-for="v-name"
                      >
                        <b-form-input
                          id="v-name"
                          :placeholder="$t('Name')"
                          v-model="role.name"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Shoppers -->
                    <b-col cols="12">
                      <h4>{{$t('Shoppers')}}</h4>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Show-Shoppers"
                          :checked="role.permissions_ids.includes(permissions_ids.index_shoppers) ? true : false"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.index_shoppers)"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Show Shoppers')}}</span>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Show-Shoppers-Details"
                          :checked="role.permissions_ids.includes(permissions_ids.show_shoppers) ? true : false"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.show_shoppers)"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Show Shoppers Details')}}</span>
                    </b-col>
                    <!-- Categories -->
                    <b-col cols="12">
                      <hr>
                      <h4>{{$t('Categories')}}</h4>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Show-Categories"
                          :checked="role.permissions_ids.includes(permissions_ids.index_categories) ? true : false"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.index_categories)"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Show Categories')}}</span>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Create Categories"
                          :checked="role.permissions_ids.includes(permissions_ids.create_categories) ? true : false"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.create_categories)"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Create Category')}}</span>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Edit Categories"
                          :checked="role.permissions_ids.includes(permissions_ids.edit_categories) ? true : false"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.edit_categories)"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Edit Category')}}</span>
                    </b-col>
                    <!-- Products -->
                    <b-col cols="12">
                      <hr>
                      <h4>{{$t('Products')}}</h4>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Show-Products"
                          :checked="role.permissions_ids.includes(permissions_ids.index_products) ? true : false"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.index_products)"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Show Products')}}</span>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Create-Product"
                          :checked="role.permissions_ids.includes(permissions_ids.create_products) ? true : false"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.create_products)"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Create Product')}}</span>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Edit-Product"
                          :checked="role.permissions_ids.includes(permissions_ids.edit_products) ? true : false"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.edit_products)"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Edit Product')}}</span>
                    </b-col>
                    <!-- Orders -->
                    <b-col cols="12">
                      <hr>
                      <h4>{{$t('Orders')}}</h4>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Show-Orders"
                          :checked="role.permissions_ids.includes(permissions_ids.index_orders) ? true : false"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.index_orders)"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Show Orders')}}</span>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Show-Order"
                          :checked="role.permissions_ids.includes(permissions_ids.show_orders) ? true : false"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.show_orders)"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Show Order')}}</span>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Change-order-history"
                          :checked="role.permissions_ids.includes(permissions_ids.add_order_history) ? true : false"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.add_order_history)"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Change order history')}}</span>
                    </b-col>

                    <!-- Marketing Offers -->
                    <b-col cols="12">
                      <hr>
                      <h4>{{$t('Marketing Offers')}}</h4>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-index_marketing_offers"
                          :checked="role.permissions_ids.includes(permissions_ids.index_marketing_offers) ? true : false"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.index_marketing_offers)"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Show Marketing Offers')}}</span>
                    </b-col>

                    <!-- Coupons -->
                    <b-col cols="12">
                      <hr>
                      <h4>{{$t('Coupons')}}</h4>
                    </b-col>
                    <b-col cols="6">
                      <b-form-checkbox
                        id="v-Coupons"
                        :checked="role.permissions_ids.includes(permissions_ids.index_coupon) ? true : false"
                        class="custom-control-primary"
                        @change="checkPermissions(permissions_ids.index_coupon)"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <span class="font-weight-bold">{{$t('Show Coupons')}}</span>
                    </b-col>
                    <b-col cols="6">
                      <b-form-checkbox
                        id="v-Create-Coupons"
                        :checked="role.permissions_ids.includes(permissions_ids.create_coupon) ? true : false"
                        class="custom-control-primary"
                        @change="checkPermissions(permissions_ids.create_coupon)"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <span class="font-weight-bold">{{$t('Create Coupons')}}</span>
                    </b-col>
                    <b-col cols="6">
                      <b-form-checkbox
                        id="v-Edit-Coupons"
                        :checked="role.permissions_ids.includes(permissions_ids.edit_coupon) ? true : false"
                        class="custom-control-primary"
                        @change="checkPermissions(permissions_ids.edit_coupon)"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <span class="font-weight-bold">{{$t('Edit Coupons')}}</span>
                    </b-col>

                    <!-- Store Design -->
                    <b-col cols="12">
                      <hr>
                      <h4>{{$t('Store Design')}}</h4>
                    </b-col>
                    <b-col cols="6">
                      <b-form-checkbox
                        id="v-Show-Store-Design"
                        :checked="role.permissions_ids.includes(permissions_ids.show_store_design) ? true : false"
                        class="custom-control-primary"
                        @change="checkPermissions(permissions_ids.show_store_design)"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <span class="font-weight-bold">{{$t('Show Store Design')}}</span>
                    </b-col>

                    <!-- Store Design -->
                    <b-col cols="12">
                      <hr>
                      <h4>{{$t('Reports')}}</h4>
                    </b-col>
                    <b-col cols="6">
                      <b-form-checkbox
                        id="v-Reports"
                        :checked="role.permissions_ids.includes(permissions_ids.show_reports) ? true : false"
                        class="custom-control-primary"
                        @change="checkPermissions(permissions_ids.show_reports)"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <span class="font-weight-bold">{{$t('Show Reports')}}</span>
                    </b-col>

                    <!-- Store Configurations -->
                    <b-col cols="12">
                      <hr>
                      <h4>{{$t('Store Configurations')}}</h4>
                    </b-col>
                    <b-col cols="6">
                      <b-form-checkbox
                        id="v-show_store_info"
                        :checked="role.permissions_ids.includes(permissions_ids.show_store_info) ? true : false"
                        class="custom-control-primary"
                        @change="checkPermissions(permissions_ids.show_store_info)"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <span class="font-weight-bold">{{$t('Show Store Configurations')}}</span>
                    </b-col>
                    <b-col cols="6">
                      <b-form-checkbox
                        id="v-show_payment_methods"
                        :checked="role.permissions_ids.includes(permissions_ids.show_payment_methods) ? true : false"
                        class="custom-control-primary"
                        @change="checkPermissions(permissions_ids.show_payment_methods)"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <span class="font-weight-bold">{{$t('Show Payment Methods')}}</span>
                    </b-col>
                    <b-col cols="6">
                      <b-form-checkbox
                        id="v-show_shipping_methods"
                        :checked="role.permissions_ids.includes(permissions_ids.show_shipping_methods) ? true : false"
                        class="custom-control-primary"
                        @change="checkPermissions(permissions_ids.show_shipping_methods)"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <span class="font-weight-bold">{{$t('Show Shipping Methods')}}</span>
                    </b-col>
                    <b-col cols="6">
                      <b-form-checkbox
                        id="v-show_other_services"
                        :checked="role.permissions_ids.includes(permissions_ids.show_other_services) ? true : false"
                        class="custom-control-primary"
                        @change="checkPermissions(permissions_ids.show_other_services)"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <span class="font-weight-bold">{{$t('Show Other Services')}}</span>
                    </b-col>

                    <!-- Users -->
                    <b-col cols="12">
                      <hr>
                      <h4>{{$t('Users')}}</h4>
                    </b-col>
                    <b-col cols="6">
                      <b-form-checkbox
                        id="v-Show-Users"
                        :checked="role.permissions_ids.includes(permissions_ids.show_users) ? true : false"
                        class="custom-control-primary"
                        @change="checkPermissions(permissions_ids.show_users)"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <span class="font-weight-bold">{{$t('Show Users')}}</span>
                    </b-col>

                    <!-- Pages -->
                    <b-col cols="12">
                      <hr>
                      <h4>{{$t('Pages')}}</h4>
                    </b-col>
                    <b-col cols="6">
                      <b-form-checkbox
                        id="v-Show-Pages"
                        :checked="role.permissions_ids.includes(permissions_ids.index_pages) ? true : false"
                        class="custom-control-primary"
                        @change="checkPermissions(permissions_ids.index_pages)"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <span class="font-weight-bold">{{$t('Show Pages')}}</span>
                    </b-col>
                    <b-col cols="6">
                      <b-form-checkbox
                        id="v-Create-Pages"
                        :checked="role.permissions_ids.includes(permissions_ids.create_pages) ? true : false"
                        class="custom-control-primary"
                        @change="checkPermissions(permissions_ids.create_pages)"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <span class="font-weight-bold">{{$t('Create Page')}}</span>
                    </b-col>
                    <b-col cols="6">
                      <b-form-checkbox
                        id="v-Edit-Pages"
                        :checked="role.permissions_ids.includes(permissions_ids.edit_pages) ? true : false"
                        class="custom-control-primary"
                        @change="checkPermissions(permissions_ids.edit_pages)"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <span class="font-weight-bold">{{$t('Edit Page')}}</span>
                    </b-col>

                    <!-- Subscriptions -->
                    <b-col cols="12">
                      <hr>
                      <h4>{{$t('Subscriptions')}}</h4>
                    </b-col>
                    <b-col cols="6">
                      <b-form-checkbox
                        id="v-Show-Subscriptions"
                        :checked="role.permissions_ids.includes(permissions_ids.index_subscraptions) ? true : false"
                        class="custom-control-primary"
                        @change="checkPermissions(permissions_ids.index_subscraptions)"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <span class="font-weight-bold">{{$t('Show Subscriptions')}}</span>
                    </b-col>

                    <!-- Mhd Store -->
                    <b-col cols="12">
                      <hr>
                      <h4>{{$t('Mhd Store')}}</h4>
                    </b-col>
                    <b-col cols="6">
                      <b-form-checkbox
                        id="v-Show-Mhd-Store"
                        :checked="role.permissions_ids.includes(permissions_ids.index_mhd_store) ? true : false"
                        class="custom-control-primary"
                        @change="checkPermissions(permissions_ids.index_mhd_store)"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <span class="font-weight-bold">{{$t('Show Mhd Store')}}</span>
                    </b-col>
                    
                    <!-- Shipping Zones -->
                    <b-col cols="12">
                      <hr>
                      <h4>{{$t('Shipping Zones')}}</h4>
                    </b-col>
                    <b-col cols="6">
                      <b-form-checkbox
                        id="v-show_orders_shipping_zones"
                        :checked="role.permissions_ids.includes(permissions_ids.show_orders_shipping_zones) ? true : false"
                        class="custom-control-primary"
                        @change="checkPermissions(permissions_ids.show_orders_shipping_zones)"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <span class="font-weight-bold">{{$t('Show Order For Delivery')}}</span>
                    </b-col>

                    <!-- submit -->
                    <b-col cols="12">
                      <hr>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mr-1"
                      >
                        <template v-if="!isLoadingSave">
                          {{$t('Save')}}
                        </template>
                        <template v-if="isLoadingSave">
                          {{$t('Saveing')}}...
                          <b-spinner small />
                        </template>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
            </b-card>
          </b-col>
          </transition>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  name: 'RolesEdit',

  data() {
    return {
      isLoading: false,
      isLoadingSave: false,
      role: {
        permissions_ids: []
      },
      permissions_ids: {
        show_shoppers: 1,
        index_shoppers: 2,
        index_categories: 3,
        edit_categories: 4,
        create_categories: 5,
        index_products: 6,
        edit_products: 7,
        create_products: 8,
        index_orders: 9,
        show_orders: 10,
        add_order_history: 11,
        index_marketing_offers: 12,
        index_coupon: 13,
        edit_coupon: 14,
        create_coupon: 15,
        index_banners: 16,
        edit_banners: 17,
        create_banners: 18,
        index_featured_prooducts: 19,
        edit_featured_prooducts: 20,
        create_featured_prooducts: 21,
        show_reports: 22,
        show_store_info: 23,
        show_store_design: 24,
        show_payment_methods: 25,
        show_shipping_methods: 26,
        show_other_services: 27,
        show_users: 28,
        index_pages: 29,
        edit_pages: 30,
        create_pages: 31,
        index_subscraptions: 32,
        create_subscraptions: 33,
        index_mhd_store: 34,
        show_orders_shipping_zones: 35,
      }
    }
  },
  mounted() {
      this.getRole();
  },
  methods: {
    getRole(){
        this.isLoading = true
        useJwt.post('/roles/get-by-id',{id: this.$route.params.id})
        .then((response) => {
          // console.log(response.data)
          this.role = response.data.data
          this.isLoading = false
        })
        .catch(response => {
          // console.log(response);
        });
    },
    update() {
      this.isLoadingSave = true;
      useJwt.post('/roles/update',this.role)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
      })
      .catch(error => {
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message.title)
      });
    },
    checkPermissions(permission){
      if (this.role.permissions_ids.includes(permission)) {
        // remove
        this.role.permissions_ids.splice(this.role.permissions_ids.indexOf(permission), 1);
      } else {
        // add
        this.role.permissions_ids[this.role.permissions_ids.length] = permission
      }
      console.log(this.role.permissions_ids)
    }
  }
}
</script>
<style lang="scss">
.ql-editor {
  height: 250px;
}
</style>
